import { FunctionComponent, ReactElement, useState } from "react"
import styled from "@emotion/styled"
import { Global, css } from "@emotion/react"

import {
  CloseIcon,
  GridRow,
  MenuIcon,
  PrimaryTwoCTA,
  breakpoints,
  colours,
  column,
  resets,
  vertical,
  horizontal,
  iconSize,
} from "design-kit"

import { SkipLink } from "./SkipLink"
import { Variation } from "../Logo"
import Auth from "./internal/Auth"
import { Mobile, ctaContentWidth } from "./internal/Breakpoints"
import Logo, { PartnerLogo } from "./internal/Logo"
import Items, { NavItem } from "./internal/Items"
import { NavTheme } from "./internal/Theme"

const Header = styled.header<{
  navTheme: NavTheme
  mobileMenuOpen: boolean
  sticky: boolean
}>`
  background-color: ${props =>
    props.mobileMenuOpen || props.navTheme === "opaque"
      ? colours.white
      : "transparent"};

  padding-bottom: ${vertical.xs};
  position: ${props => (props.sticky ? "fixed" : "absolute")};
  top: 0;
  width: 100%;
  z-index: 3;

  ${props =>
    props.mobileMenuOpen
      ? `
        position: fixed;
        bottom: 0;
        overflow-y: auto;
      `
      : ""}
`

const HeaderInner = styled.div<{ navTheme: NavTheme }>`
  // Just full-width
  ${column({ widthMobile: 4, widthTablet: 6, widthDesktop: 12 })}
  margin-top: ${vertical.xs};

  ${breakpoints.desktop`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${breakpoints.desktop`
    width: auto;
    margin-right: ${horizontal.m};
  `}
`

const MobileContainer = styled.div<{ visible: boolean }>`
  display: ${props => (props.visible ? "block" : "none")};

  ${breakpoints.desktop`
    display: flex;
    align-items: center;
  `}
`

const ItemsContainer = styled.div`
  margin-top: ${vertical.s};

  ${breakpoints.desktop`
    margin-top: 0;
    margin-left: auto;
  `}
`

const CloseButton = styled.button`
  ${resets.button};

  &:hover {
    > svg {
      stroke: ${colours.action.main};
    }
  }
`

type Navbar = FunctionComponent<{
  theme?: NavTheme
  logoVariation?: Variation
  partnerLogo?: PartnerLogo
  items?: NavItem[]
  hasAuth?: boolean
  sticky?: boolean
  banner?: ReactElement
}>

const Navbar: Navbar = ({
  theme = "opaque",
  logoVariation = "Habito",
  partnerLogo,
  items = [],
  hasAuth = true,
  sticky = false,
  banner,
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false)

  const hasItems = items.length > 0

  return (
    <Header navTheme={theme} mobileMenuOpen={mobileMenuOpen} sticky={sticky}>
      <Global
        styles={css`
          body {
            ${mobileMenuOpen ? "overflow: hidden;" : ""}
          }
        `}
      />

      <SkipLink />

      {banner}

      <GridRow>
        <HeaderInner navTheme={theme}>
          <LogoContainer>
            <Logo
              partnerLogo={partnerLogo}
              navTheme={theme}
              variation={logoVariation}
              mobileMenuOpen={mobileMenuOpen}
            />

            <Mobile>
              {mobileMenuOpen ? (
                <CloseButton
                  aria-label="Close menu"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <CloseIcon />
                </CloseButton>
              ) : (
                <PrimaryTwoCTA
                  onClick={() => setMobileMenuOpen(true)}
                  width={ctaContentWidth}
                  aria-label="Open menu"
                  icon={{
                    kind: "left",
                    icon: <MenuIcon width={iconSize.xs} />,
                  }}
                  text="Menu"
                />
              )}
            </Mobile>
          </LogoContainer>

          <MobileContainer visible={mobileMenuOpen}>
            {hasItems && (
              <ItemsContainer>
                <Items items={items} navTheme={theme} />
              </ItemsContainer>
            )}

            {hasAuth && <Auth navTheme={theme} />}
          </MobileContainer>
        </HeaderInner>
      </GridRow>
    </Header>
  )
}
export default Navbar
