import styled from "@emotion/styled"
import { ButtonWidth, breakpoints } from "design-kit"

export const Mobile = styled.div`
  ${breakpoints.desktop`
    display: none;
  `}
`

export const Desktop = styled.div`
  display: none;

  ${breakpoints.desktop`
    display: block;
  `}
`

export const ctaContentWidth: ButtonWidth = "content-width"
