import React from "react"

/**
 * This hook accepts a media query string then uses the
 * [window.matchMedia](https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia)
 * API to return true if and only if the given query matches the active
 * document. It also monitors the document for changes to the matched state.
 *
 * Will consistently (across SSR & browser render) return the `initial` value on first run.
 * This defaults to `false` if not secified.
 * If it didn't, we run into funky rehydration issue.
 */
export function useMediaQuery(mediaQuery: string, initial = false): boolean {
  const [matches, setMatches] = React.useState<boolean>()

  React.useEffect(() => {
    const getMatchValue = (): boolean =>
      typeof window !== "undefined"
        ? !!window.matchMedia(mediaQuery).matches
        : initial

    if (matches === undefined && typeof window !== "undefined") {
      setMatches(getMatchValue())
    }
  }, [initial, mediaQuery, matches])

  React.useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery)
    const documentChangeHandler = (): void =>
      setMatches(!!mediaQueryList.matches)

    mediaQueryList.addListener(documentChangeHandler)

    documentChangeHandler()
    return () => {
      mediaQueryList.removeListener(documentChangeHandler)
    }
  }, [mediaQuery])

  return matches === undefined ? initial : matches
}

/**
 * Use this hook to determine whether a component has mounted client-side. This
 * shouldn't normally be needed unless your application is doing server-side
 * rendering, in which case you may sometimes need to force a re-render; for
 * example, when using the `useMediaQuery` hook above.
 */
export function useHasMounted(): boolean {
  const [hasMounted, setHasMounted] = React.useState<boolean>(false)

  React.useEffect(() => {
    setHasMounted(true)
  }, [])

  return hasMounted
}
