import React from "react"
import { css } from "@emotion/react"

import {
  borderRadii,
  colours,
  horizontal,
  mixins,
  resets,
  typographyStyles,
  vertical,
} from "design-kit"

export const SkipLink: React.FC = () => (
  <a
    href="#main-content"
    css={css`
      ${resets.anchor}
      position: fixed;
      left: -100%;
      display: block;
      ${typographyStyles.bodyBold}
      color: ${colours.offBlack};
      background-color: ${colours.white};
      border-radius: ${borderRadii.xs};
      padding: ${vertical.xs} ${horizontal.m};

      :focus,
      :active {
        top: 16px;
        left: 16px;
        z-index: 40;
        ${mixins.focused}
      }
    `}
  >
    <span
      css={css`
        padding-bottom: 2px;
        border-bottom: 2px solid ${colours.offBlack};
      `}
    >
      Skip to main content
    </span>
  </a>
)
