import React, { FunctionComponent, VoidFunctionComponent } from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

import {
  ArrowRightIcon,
  ApplyProminence,
  ChevronDownIcon,
  ChevronUpIcon,
  colours,
  ButtonCTAProps,
  LinkCTAProps,
  PrimaryTwoLink,
  TertiaryTwoCTA,
  TertiaryTwoInvertedCTA,
  TertiaryTwoLink,
  TertiaryTwoInvertedLink,
  TertiaryProminence,
  breakpoints,
  horizontal,
  typographyStyles,
  MetaText,
} from "design-kit"

import { Desktop, Mobile, ctaContentWidth } from "./Breakpoints"
import Dropdown from "./Dropdown"
import { NavTheme, NavThemed } from "./Theme"
import { registerButtonClick } from "../../../utils/amplitude"

/*****
 * API
 *****
 *
 * A navigation bar consists of a list of top-level items. Each item may either
 * be a direct link or a dropdown with a sublist of links. Dropdowns may not be
 * nested (it is not possible to have a dropdown within another dropdown).
 */

export type NavItem = TopLevelLink | TopLevelDropdown

type TopLevelLink = {
  _tag: "link"
  text: string
  href: string
  buttonName: string
}

type TopLevelDropdown = {
  _tag: "dropdown"
  text: string
  accessibleMenuName: string
  items: (DropdownItem | DropdownSection)[]
}

type DropdownItem = {
  _tag: "item"
  text: string
  href: string
  target?: "_blank"
  buttonName: string
}

type DropdownSection = {
  _tag: "section"
  name: string
  items: DropdownItem[]
}

/**************
 * Core "atoms"
 **************
 */

export const MobileItemLink = styled(TertiaryTwoLink)<
  NavThemed<React.ComponentPropsWithoutRef<typeof TertiaryTwoLink>>
>`
  > span {
    ${typographyStyles.headlineBold}
  }
`

export const MobileDropdownCTA = styled(TertiaryTwoCTA)<
  NavThemed<React.ComponentPropsWithoutRef<typeof TertiaryTwoCTA>>
>`
  > span {
    ${typographyStyles.headlineBold}
  }
`

export const MobileDropdownLink = styled(TertiaryTwoLink)<
  NavThemed<React.ComponentPropsWithoutRef<typeof TertiaryTwoLink>>
>``

export const MobilePrimaryItemLink = styled(PrimaryTwoLink)<
  NavThemed<React.ComponentPropsWithoutRef<typeof PrimaryTwoLink>>
>``

export const DesktopItemLink: React.FC<
  NavThemed<ApplyProminence<TertiaryProminence, LinkCTAProps>>
> = ({ navTheme, ...props }) =>
  navTheme === "opaque" || navTheme === "transparent-dark" ? (
    <TertiaryTwoLink {...props} />
  ) : (
    <TertiaryTwoInvertedLink {...props} />
  )

export const DesktopDropdownCTA: React.FC<
  NavThemed<ApplyProminence<TertiaryProminence, ButtonCTAProps>>
> = ({ navTheme, ...props }) =>
  navTheme === "opaque" || navTheme === "transparent-dark" ? (
    <TertiaryTwoCTA {...props} />
  ) : (
    <TertiaryTwoInvertedCTA {...props} />
  )

export const DesktopDropdownLink = styled(TertiaryTwoLink)<
  NavThemed<React.ComponentPropsWithoutRef<typeof TertiaryTwoLink>>
>``

export const DesktopPrimaryItemLink = styled(PrimaryTwoLink)<
  NavThemed<React.ComponentPropsWithoutRef<typeof PrimaryTwoLink>>
>``

/*****************
 * Top-level links
 *****************
 */

type TopLevelLinkProps = {
  buttonName: string
  href: string
  navTheme: NavTheme
  text: string
}

const TopLevelLink: VoidFunctionComponent<TopLevelLinkProps> = ({
  buttonName,
  href,
  navTheme,
  text,
}) => (
  <React.Fragment>
    <Mobile>
      <MobileItemLink
        navTheme={navTheme}
        href={href}
        onClick={() => registerButtonClick("nav_bar")(buttonName)}
        width={ctaContentWidth}
        text={text}
      />
    </Mobile>
    <Desktop>
      <DesktopItemLink
        navTheme={navTheme}
        href={href}
        onClick={() => registerButtonClick("nav_bar")(buttonName)}
        width={ctaContentWidth}
        text={text}
      />
    </Desktop>
  </React.Fragment>
)

/*********************
 * Top-level dropdowns
 *********************
 */

type TopLevelDropdownProps = {
  text: string
  accessibleMenuName: string
  navTheme: NavTheme
  items: (DropdownItem | DropdownSection)[]
}

const TopLevelDropdown: FunctionComponent<TopLevelDropdownProps> = ({
  items,
  navTheme,
  text,
  accessibleMenuName,
}) => {
  return (
    <Dropdown
      render={({ expanded, toggleExpanded }) => (
        <React.Fragment>
          <Mobile>
            <MobileDropdownCTA
              navTheme={navTheme}
              onClick={toggleExpanded}
              aria-expanded={expanded}
              aria-label={
                expanded
                  ? "Close " + accessibleMenuName + " menu"
                  : "Open " + accessibleMenuName + " menu"
              }
              width={ctaContentWidth}
              icon={{
                kind: "right",
                icon: expanded ? <ChevronUpIcon /> : <ChevronDownIcon />,
              }}
              text={text}
            />
          </Mobile>
          <Desktop>
            <DesktopDropdownCTA
              navTheme={navTheme}
              onClick={toggleExpanded}
              aria-expanded={expanded}
              aria-label={
                expanded
                  ? "Close " + accessibleMenuName + " menu"
                  : "Open " + accessibleMenuName + " menu"
              }
              width={ctaContentWidth}
              icon={{
                kind: "right",
                icon: expanded ? <ChevronUpIcon /> : <ChevronDownIcon />,
              }}
              text={text}
            />
          </Desktop>
        </React.Fragment>
      )}
    >
      {items.map(item => buildDropdown(item, navTheme))}
    </Dropdown>
  )
}

function buildDropdown(
  entry: DropdownItem | DropdownSection,
  navTheme: NavTheme
): React.ReactNode {
  switch (entry._tag) {
    case "item":
      return (
        <DropdownItem
          key={entry.text}
          buttonName={entry.buttonName}
          href={entry.href}
          target={entry.target}
          navTheme={navTheme}
          text={entry.text}
        />
      )
    case "section":
      return (
        <section
          key={entry.name}
          css={css`
            &:not(:first-child) {
              margin-top: 1px;

              // The 'box-shadow' draws a thin line at the top of the section,
              // visually separating it from the previous section.
              box-shadow: 0px -18px 0px -16.5px ${colours.greyScale.grey25};
            }
          `}
        >
          <MetaText
            css={css`
              padding-top: 12px;
              margin-bottom: 1px;
              margin-left: ${horizontal.s};
              margin-right: ${horizontal.s};
            `}
          >
            {entry.name}
          </MetaText>
          {entry.items.map(item => buildDropdown(item, navTheme))}
        </section>
      )
  }
}

export const DropdownItem: FunctionComponent<
  Omit<DropdownItem, "_tag"> & {
    navTheme: NavTheme
  }
> = ({ buttonName, href, target, navTheme, text }) => (
  <DropdownListItem>
    <Mobile>
      <MobileDropdownLink
        navTheme={navTheme}
        href={href}
        target={target}
        icon={{
          kind: "right",
          icon: <ArrowRightIcon />,
        }}
        onClick={() => registerButtonClick("nav_bar")(buttonName)}
        text={text}
        width={ctaContentWidth}
      />
    </Mobile>
    <Desktop>
      <DesktopDropdownLink
        navTheme={navTheme}
        href={href}
        target={target}
        icon={{
          kind: "right",
          icon: <ArrowRightIcon />,
        }}
        onClick={() => registerButtonClick("nav_bar")(buttonName)}
        text={text}
        width={ctaContentWidth}
      />
    </Desktop>
  </DropdownListItem>
)

const DropdownListItem = styled.li`
  align-items: center;
  display: flex;
  margin-left: ${horizontal.s};
  margin-right: ${horizontal.s};
`

/********************************
 * Full lists of navigation items
 ********************************
 */

type Items = FunctionComponent<{
  items: NavItem[]
  navTheme: NavTheme
}>

const Items: Items = ({ items, navTheme }) => (
  <nav>
    <ItemsList>
      {items.map((item, i) => {
        switch (item._tag) {
          case "link":
            return (
              <ItemsListItem key={i}>
                <TopLevelLink
                  buttonName={item.buttonName}
                  href={item.href}
                  navTheme={navTheme}
                  text={item.text}
                />
              </ItemsListItem>
            )
          case "dropdown":
            return (
              <ItemsListItem key={i}>
                <TopLevelDropdown
                  text={item.text}
                  accessibleMenuName={item.accessibleMenuName}
                  items={item.items}
                  navTheme={navTheme}
                />
              </ItemsListItem>
            )
        }
      })}
    </ItemsList>
  </nav>
)

export default Items

export const ItemsList = styled.ul`
  ${breakpoints.desktop`
    align-items: center;
    display: flex;
  `}
`

export const ItemsListItem = styled.li`
  position: relative;

  ${breakpoints.desktop`
    margin-right: ${horizontal.m};
  `}
`
