import { FunctionComponent } from "react"
import styled from "@emotion/styled"

import {
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronRightIcon,
  Body,
  vertical,
} from "design-kit"

import { UserContext } from "../../../shared-components/Shared/UserProvider"
import { Mobile, Desktop, ctaContentWidth } from "./Breakpoints"
import Dropdown from "./Dropdown"
import {
  DesktopDropdownCTA,
  DesktopItemLink,
  DesktopPrimaryItemLink,
  DropdownItem,
  ItemsList,
  ItemsListItem,
  MobileItemLink,
  MobilePrimaryItemLink,
} from "./Items"
import { NavTheme } from "./Theme"
import { registerButtonClick } from "../../../utils/amplitude"

const Container = styled.div`
  position: relative;
`

const MobileAuthListItem = styled.li`
  height: 48px;
  margin-top: ${vertical.xs};
`

/******************
 * Logged-in states
 ******************
 */

function truncateLongEmail(email: string): string {
  // We display signed-in customers' email addresses in the nav bar. If the
  // address is too long, it causes layout problems. So we want to limit the
  // visual length of the email address. We leave the '@' in place, and
  // truncate either side of it as necessary.

  const maxLength = 28 // characters, including '@'
  const nCharsToDrop = email.length - maxLength

  if (nCharsToDrop > 0) {
    const [localPart, domain] = email.split("@")

    // Throughout the rest of this function, all the `+ 1`s are there to
    // account for the length of the `…` character.

    if (
      localPart.length >= domain.length &&
      localPart.length > nCharsToDrop + 1
    ) {
      // If the localPart is long, leave the domain in tact (un-truncated).
      const truncatedLocalPart = localPart.slice(0, -(nCharsToDrop + 1)) + "…"
      return truncatedLocalPart + "@" + domain
    } else if (
      localPart.length < domain.length &&
      domain.length > nCharsToDrop + 1
    ) {
      // Likewise, if the domain is long, leave the localPart in tact.
      const truncatedDomain = "…" + domain.slice(nCharsToDrop + 1)
      return localPart + "@" + truncatedDomain
    } else {
      // If we need to drop characters from both localPart and domain, drop
      // equally(ish) from both.
      const nCharsToDropFromLocalPart = Math.ceil(nCharsToDrop / 2)
      const nCharsToDropFromDomain = nCharsToDrop - nCharsToDropFromLocalPart
      const truncatedLocalPart =
        localPart.slice(0, -(nCharsToDropFromLocalPart + 1)) + "…"
      const truncatedDomain = "…" + domain.slice(nCharsToDropFromDomain + 1)
      return truncatedLocalPart + "@" + truncatedDomain
    }
  } else {
    return email
  }
}

type LoggedIn = FunctionComponent<{ navTheme: NavTheme; userEmail: string }>
const LoggedIn: LoggedIn = ({ navTheme, userEmail }) => {
  return (
    <Container>
      <Mobile>
        <ItemsList>
          <MobileAuthListItem>
            <EmailAddressBody>{userEmail}</EmailAddressBody>
          </MobileAuthListItem>
          <MobileAuthListItem>
            <MobilePrimaryItemLink
              navTheme={navTheme}
              href="/my-properties"
              icon={{
                kind: "right",
                icon: <ChevronRightIcon />,
              }}
              onClick={() => registerButtonClick("nav_bar")("nav_dashboard")}
              text="Dashboard"
            />
          </MobileAuthListItem>
          <MobileAuthListItem>
            <MobileItemLink
              navTheme={navTheme}
              href="/sign-out"
              width={ctaContentWidth}
              text="Log out"
            />
          </MobileAuthListItem>
        </ItemsList>
      </Mobile>
      <Desktop>
        <Dropdown
          render={({ expanded, toggleExpanded }) => (
            <DesktopDropdownCTA
              navTheme={navTheme}
              onClick={toggleExpanded}
              aria-expanded={expanded}
              aria-label={expanded ? "Close user menu" : "Open user menu"}
              width={ctaContentWidth}
              icon={{
                kind: "right",
                icon: expanded ? <ChevronUpIcon /> : <ChevronDownIcon />,
              }}
              text={truncateLongEmail(userEmail)}
            />
          )}
        >
          <DropdownItem
            navTheme={navTheme}
            buttonName="nav_auth_my_properties"
            href="/my-properties"
            text="Dashboard"
          />
          <DropdownItem
            navTheme={navTheme}
            buttonName="nav_auth_sign_out"
            href="/sign-out"
            text="Log out"
          />
        </Dropdown>
      </Desktop>
    </Container>
  )
}

const EmailAddressBody = styled(Body)`
  line-height: 48px;
`

/*******************
 * Logged out states
 *******************
 */

type LoggedOut = FunctionComponent<{ navTheme: NavTheme }>
const LoggedOut: LoggedOut = ({ navTheme }) => (
  <Container>
    <Mobile>
      <ItemsList>
        <MobileAuthListItem>
          <MobileItemLink
            navTheme={navTheme}
            href="/log-in"
            width={ctaContentWidth}
            onClick={() => registerButtonClick("nav_bar")("nav_login")}
            text="Log in"
          />
        </MobileAuthListItem>
        <MobileAuthListItem>
          <MobilePrimaryItemLink
            navTheme={navTheme}
            href="/how-can-we-help"
            icon={{
              kind: "right",
              icon: <ChevronRightIcon />,
            }}
            onClick={() => registerButtonClick("nav_bar")("nav_get_started")}
            text="Get started"
          />
        </MobileAuthListItem>
      </ItemsList>
    </Mobile>
    <Desktop>
      <ItemsList>
        <ItemsListItem>
          <DesktopItemLink
            navTheme={navTheme}
            href="/log-in"
            width={ctaContentWidth}
            onClick={() => registerButtonClick("nav_bar")("nav_login")}
            text="Log in"
          />
        </ItemsListItem>
        <ItemsListItem>
          <DesktopPrimaryItemLink
            navTheme={navTheme}
            text="Get started"
            href="/how-can-we-help"
            onClick={() => registerButtonClick("nav_bar")("nav_get_started")}
          />
        </ItemsListItem>
      </ItemsList>
    </Desktop>
  </Container>
)

/******************************************
 * Authentication navigation bar components
 ******************************************
 */

type Auth = FunctionComponent<{ navTheme: NavTheme }>
const Auth: Auth = ({ navTheme }) => (
  <UserContext.Consumer>
    {user =>
      user ? (
        <LoggedIn navTheme={navTheme} userEmail={user.email} />
      ) : (
        <LoggedOut navTheme={navTheme} />
      )
    }
  </UserContext.Consumer>
)

export default Auth
