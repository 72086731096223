import React, { FunctionComponent } from "react"
import styled from "@emotion/styled"

import {
  breakpoints,
  borderRadii,
  colours,
  useMediaQuery,
  vertical,
} from "design-kit"

const habitoEase = `cubic-bezier(.25,.01,.25,1)`

const Outer = styled.div<{ expanded: boolean }>`
  display: ${props => (props.expanded ? "block" : "none")};

  &:hover {
    display: block;
  }

  ${breakpoints.desktop`
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding-top: ${vertical.xxs};
    z-index: -1;
  `}
`

const Inner = styled.ul<{ expanded: boolean }>`
  ${breakpoints.desktop`
    position: relative;
    background-color: ${colours.white};
    border: 1px solid ${colours.greyScale.grey50};
    border-radius: ${borderRadii.xs};

    animation: appear 0.3s;
    animation-timing-function: ${habitoEase};

    @keyframes appear {
      0% {
        transform: translateY(-30px);
        opacity: 0;
      }
      100% {
        transform: translateY(0%, 0);
        opacity: 1;
      }
    }

    & > li {
      opacity: 0;
      transition: opacity 0.3s ${habitoEase};
      transition-delay: 0.1s;
    }
  `}

  ${props =>
    props.expanded
      ? breakpoints.desktop`
        & > li {
          opacity: 1;
        }
        `
      : ""}

  &:hover {
    & > li {
      opacity: 1;
    }
  }
`

type MouseEnterLeaveHandlers = {
  handleMouseEnter: () => void
  handleMouseLeave: () => void
}

const doNothingEventHandlers: MouseEnterLeaveHandlers = {
  handleMouseEnter: () => {
    return
  },
  handleMouseLeave: () => {
    return
  },
}

const DROPDOWN_INTENT_DURATION = 250

const createHoverIntentHandlers: (
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>
) => MouseEnterLeaveHandlers = setExpanded => {
  let timer: number
  const open = (): void => {
    window.clearTimeout(timer)
    setExpanded(true)
  }

  const closeAfterDelay = (): void => {
    timer = window.setTimeout(
      () => setExpanded(false),
      DROPDOWN_INTENT_DURATION
    )
  }

  return {
    handleMouseEnter: open,
    handleMouseLeave: closeAfterDelay,
  }
}

type DropdownProps = {
  render: (props: DropdownRenderProps) => React.ReactElement
}

type DropdownRenderProps = {
  expanded: boolean
  toggleExpanded: () => void
}

// On mobile this works nicely as a collapsible menu. On desktop it's intended
// as a dropdown that's toggled on click or on hover.
const Dropdown: FunctionComponent<DropdownProps> = ({ children, render }) => {
  const [expanded, setExpanded] = React.useState(false)
  const toggleExpanded = (): void => setExpanded(e => !e)

  const isDesktop = useMediaQuery(breakpoints.desktop.query)
  const { handleMouseEnter, handleMouseLeave } = isDesktop
    ? createHoverIntentHandlers(setExpanded)
    : doNothingEventHandlers

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {render({ expanded, toggleExpanded })}
      <Outer expanded={expanded}>
        <Inner expanded={expanded}>{children}</Inner>
      </Outer>
    </div>
  )
}

export default Dropdown
