import React from "react"

import styled from "@emotion/styled"

import { HabitoLogo, IntermediariesLogo, mixins, PlusLogo } from "design-kit"

const AnchorTag = styled.a`
  display: block;
  padding: 4px;
  border-radius: 4px;
  box-sizing: border-box;

  &:focus {
    ${mixins.focused};
  }
`

export type Variation = "Habito" | "Habito Intermediaries" | "Habito Plus"

interface LogoProps {
  variation: Variation
  width?: number
  height?: number
  color?: string
}

const Logo: React.FunctionComponent<LogoProps> = props => {
  switch (props.variation) {
    case "Habito":
      return <HabitoLogo {...props} />
    case "Habito Intermediaries":
      return <IntermediariesLogo {...props} />
    case "Habito Plus":
      return <PlusLogo color={props.color} {...props} />
  }
}

interface Props extends LogoProps {
  href: string
}

const View: React.FunctionComponent<Props> = props => (
  <AnchorTag href={props.href}>
    <Logo {...props} />
  </AnchorTag>
)

export default View
