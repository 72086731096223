// Loosely based on https://meyerweb.com/eric/tools/css/reset/
//
import { css } from "@emotion/react"

const headingResets = css`
  margin: 0;
  padding: 0;
`

const listResets = css`
  padding: 0;
  margin: 0;
  list-style: none;
`

const anchorResets = css`
  text-decoration: none;
  color: inherit;
`

const buttonResets = css`
  background: none;
  border: none;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  overflow: visible;
  padding: 0;
  text-transform: none;
  -webkit-appearance: button;
`

export const resets = {
  heading: headingResets,
  list: listResets,
  anchor: anchorResets,
  button: buttonResets,
}
